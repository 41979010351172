import React, { useState, useEffect } from "react";

import axios from "axios";

import { isMobile } from "react-device-detect";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { useParams, useNavigate, Link } from "react-router-dom";

import "simplebar/dist/simplebar.min.css";

import Footer from "../Footer";

const Home = () => {
  const { id } = useParams();

  const { classid } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const [task, setTask] = useState([]);


  const [msg, setMsg] = useState(null);

  const fetchData = () => {
    axios({
      method: "post",
      url: "./",
      data: {
        id: id,
        classd: classid,
        studid: null
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (view.message === "failed") {
          toast.error("Error: Request Failed! Check your connection..");
          setMsg(view.message);
        } else {
          setTask(view);
          //window.scrollTo(0, document.body.scrollHeight);
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check your connection..");
      });
  };

  const [stud_id, setID] = useState(null);

  const LogIn = () => {
    const user = {
      stud_id: stud_id,
      school: id,
      token: task.token,
      title: task.title
    };

    //console.log(user);

    axios({
      method: "post",
      url: "/login.php",
      data: user,
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        const view = response.data;
        if (response.data.message === "success") {
          toast.success("Success: Login was successfull!", {
            hideProgressBar: true,
            draggable: true,
            position: "top-center",
            icon: true,
            autoClose: 6000,
          });

          localStorage.setItem("student_data", JSON.stringify(view.user_items));

          localStorage.setItem("studid", stud_id);

          localStorage.setItem("tokenid", task.token);

          navigate("../exam/"+classid);
        } else {
          toast.error("Failed: Login Failed! Incorrect Details", {
            hideProgressBar: true,
            draggable: true,
            position: "top-center",
            icon: true,
            autoClose: 6000,
          });
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check network connection. " + err);
      });
  };

  
  if (msg == "failed") {

    return (
      <div className="wrapper bg-white py-15 py-lg-4">
       
        <div class="pt-lg-8 pb-lg-16 pt-8 pb-12 bg-white">
          <div class="container">
          <div className="col-md-12 text-center py-3 mt-2">
            {isMobile ? (
              <img
                src="../assets/images/error/not-found.gif"
                alt=""
                class="rounded img-4by3-xl mb-5"
                style={{ width: "60%" }}
              />
            ) : (
              <img
              src="../assets/images/error/not-found.gif"
                alt=""
                class="rounded img-4by3-xl mb-5"
                style={{ width: "25%" }}
              />
            )}
            <h4 className="mt-4 h3 text-dark">
              Access has been blocked!{" "}
            </h4>
          </div>
          </div>
        </div>

      </div>
    );
    
  } else {
    return (
      <>
        <nav class="navbar navbar-expand-lg navbar-default">
          <div class="container-fluid px-lg-4 px-0">
            <a class="navbar-brand fs-10 text-dark">{task.task_user}</a>
          </div>
        </nav>

        <div class="pt-lg-8 pb-lg-16 pt-8 pb-12 bg-primary">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-xl-7 col-lg-7 col-md-12">
                <div>
                  <h1 class="text-white display-4 fw-semi-bold">
                    Welcome To Our School Champ!
                  </h1>
                  <p class="text-white mb-6 lead">
                    Hi there, you are welcome to our Online-Based Common
                    Entrance Test. Please make sure to read the instructions
                    before attempting test, and take note of the time you have
                    been awarded. You would get your result immediately after the
                    test! Good Luck Champ!
                  </p>
                  <div class="d-flex align-items-center">
                    <span class="text-white ms-3">
                      <i class="fe fe-user text-white"></i> {task.stud_num}{" "}
                      Enrolled{" "}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="pb-10">
          <div class="container">
            <div class="row">
              <div class="col-lg-8 col-md-12 col-12 mt-n8 mb-4 mb-lg-0">
                <div class="card rounded-3">
                  <div class="card-header border-bottom-3 p-4">
                    <div className="fs-3 fw-bold text-dark">
                      Read the Instruction below
                    </div>
                  </div>

                  <div class="card-body">
                    <div className="fs-4 mb-4">
                      <span className="fw-bold">
                        <i class="bi bi-info-circle-fill text-primary"></i>{" "}
                        Instruction
                      </span>{" "}
                      - {task.instruction}
                    </div>

                    <hr />

                    <div className="fs-4 mb-4">
                      <span className="fw-bold">
                        <i class="bi bi-stopwatch text-warning"></i> Time
                      </span>{" "}
                      - {task.timed} Mins
                    </div>

                    <hr />

                    <div className="fs-4">
                      <span className="fw-bold">
                        <i class="fe fe-calendar align-middle me-2 text-info"></i>
                        Number of questions
                      </span>{" "}
                      - {task.ques_num} Questions
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-12 col-12 mt-lg-n22">
                <div class="card mb-3 mb-4">
                  <div class="p-1">
                    <div
                      class="d-flex justify-content-center position-relative rounded py-15 border-white border rounded-3 bg-cover"
                      style={{
                        backgroundImage:
                          "url(../assets/images/course/school.jpg)",
                      }}
                    ></div>
                  </div>

                  <div class="card-body">
                    <div class="mb-3">
                      <span class="text-dark fw-bold h2">
                        {task.attend} Exam
                      </span>
                    </div>
                    <div class="mb-3">
                      <label class="form-label" for="email">
                        Examination ID
                      </label>
                      <input
                        type="text"
                        id="stud_id"
                        class="form-control border border-dark"
                        placeholder="Enter Exam ID e.g. 202XXXXXXX"
                        onChange={(e) => setID(e.target.value)}
                      />
                    </div>
                    <div class="d-grid">
                      <a
                        class="btn btn-purple text-white mb-2"
                        onClick={() => LogIn()}
                      >
                        Start Exam Now
                      </a>
                    </div>
                  </div>
                </div>

                <div class="card mb-4">
                  <div>
                    <div class="card-header">
                      <h4 class="mb-0">Important Notice</h4>
                    </div>
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item bg-transparent">
                        <i class="fe fe-award me-2 align-middle text-success"></i>
                        {task.attend}
                      </li>
                      <li class="list-group-item bg-transparent">
                        <i class="fe fe-calendar align-middle me-2 text-info"></i>
                        {task.ques_num} Questions
                      </li>
                      <li class="list-group-item bg-transparent border-bottom-0">
                        <i class="fe fe-clock align-middle me-2 text-warning"></i>
                        {task.timed} Mins
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ToastContainer />
      </>
    );
  }
};

export default Home;
