import React from "react";

import createRoot from "react-dom";

import "./index.css";

import Home from "./Home/Home";

import NoPage from "./NoPage";

import { configureStore } from "@reduxjs/toolkit";

import { Provider } from "react-redux";

import useReducer from "./features/Users";

import { HashRouter, Route, Routes } from "react-router-dom";

import axios from "axios";

import Exam from "./Exam/Exam";

import Score from "./Score/Score";


const store = configureStore({
  reducer: {
    user: useReducer,
  },
});

axios.defaults.baseURL = '/sac_exam/';

export default function App() {

  return (
    <HashRouter>
      <Routes>
        <Route path="/:id">
        <Route index path="home/:classid" element={<Home />} />
        <Route path="exam/:classid" element={<Exam />} />
        <Route path="score" element={<Score />} />
        <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </HashRouter>
  );
}

createRoot.render( <Provider store={store}><App /></Provider>, document.getElementById("root"));