import React from "react";

import { Link, useParams } from "react-router-dom";

import { useSelector } from "react-redux";

const NoPage = () => {

  const { id } = useParams();

  const year = new Date().getFullYear();

  const getusers = useSelector((state) => state.user.value);

  const token = getusers.token;

  return (
    <div>
      <div class="container d-flex flex-column">
        <div class="row">
          <div class="offset-xl-1 col-xl-4 col-lg-12 col-md-12 col-12">
            <div class="display-5 mt-4 text-dark">
              Learning Hub
            </div>
          </div>
        </div>
        <div class="row align-items-center justify-content-center g-0 py-lg-5 py-5">
          <div class="offset-xl-1 col-xl-4 col-lg-6 col-md-12 col-12 text-center text-lg-start">
            <h1 class="display-1 mb-3">404</h1>

            <p class="mb-5 lead">
              Oops! Sorry, we couldn’t find the page you were looking for. If
              you think this is a problem with us, please Contact us
            </p>
            <Link to={`./home/${token}`} class="btn btn-primary me-2">
              Back to Safety
            </Link>
          </div>

          <div class="offset-xl-1 col-xl-6 col-lg-6 col-md-12 col-12 mt-8 mt-lg-0">
            <img
              src="../assets/images/error/404-error-img.svg"
              alt=""
              class="w-100"
            />
          </div>
        </div>
        <div class="row">
          <div class="offset-xl-1 col-xl-10 col-lg-12 col-md-12 col-12">
            <div class="row align-items-center mt-6">
              <div class="col-md-6 col-8">
                <p class="mb-0">© Smart Edu. {year}.</p>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoPage;
