import React, { useState, useEffect } from "react";

import { isMobile } from "react-device-detect";

import { useParams, useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";

import { register } from "../features/Users";

import { useSelector } from "react-redux";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import "simplebar/dist/simplebar.min.css";

import axios from "axios";

import { CountdownCircleTimer } from "react-countdown-circle-timer";

const Exam = () => {
  const { id } = useParams();

  const { classid } = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const get_user_data = window.localStorage.getItem("student_data");

  const getusers = useSelector((state) => state.user.value);

  const fullname = getusers.fullname;

  let studid;

  if (getusers.studid) {
    studid = getusers.studid;
  } else {
    studid = window.localStorage.getItem("studid");
  }

  let fname_1;

  if (fullname) {
    fname_1 = fullname.split(" ")[1];
  } else {
    fname_1 = "";
  }

  window.history.pushState(null, "", window.location.href);
  window.onpopstate = function () {
    window.history.pushState(null, "", window.location.href);
  };

  useEffect(() => {
    window.scrollTo(50, 50);
    dispatch(register(JSON.parse(get_user_data)));
  }, [get_user_data]);

  const [task, setTask] = useState([]);

  const [quiz, setQuiz] = useState([]);

  const [quizlist, setQuizList] = useState([{}]);

  const [stud, setStud] = useState(studid);

  const [quizid, setQuizId] = useState(1);

  useEffect(() => {
    fetchQuizList();
    fetchData();
    fetchQuiz(quizid);
  }, [task.token]);

  const fetchData = () => {
    axios({
      method: "post",
      url: "./",
      data: {
        id: id,
        classd: classid,
        studid: studid,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (view.message === "failed") {
          //toast.error("Error: Request Failed! Check your connection..");
        } else {
          setTask(view);
          //window.scrollTo(0, document.body.scrollHeight);
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check your connection..");
      });
  };

  const fetchQuiz = (data) => {
    axios({
      method: "post",
      url: "./exam.php",
      data: {
        token: task.token,
        ques_id: data,
        id: id,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (view.message === "failed") {
          //toast.error("Error: Request Failed! Check your connection..");
          //setMsg(view.message);
        } else {
          setQuiz(view);
          //window.scrollTo(0, document.body.scrollHeight);
          window.scrollTo(200, 200);
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check your connection..");
      });
  };

  const fetchQuizList = () => {
    axios({
      method: "post",
      url: "./fetch_quiz.php",
      data: {
        token: task.token,
        id: id,
        stud_id: studid,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (view.message === "failed") {
          // toast.error("Error: Request Failed! Check your connection..");
          //setMsg(view.message);
        } else {
          setQuizList(view);
          //window.scrollTo(0, document.body.scrollHeight);
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check your connection..");
      });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      timer(window.localStorage.getItem("timer"));
      //fetchData();
    }, 12000);
    return () => clearInterval(interval);
  }, []);

  let tokens;

  //console.log(tokens);

  if (task.token) {
    tokens = task.token;
  } else {
    tokens = window.localStorage.getItem("tokenid");
  }

  const timer = (times) => {
    axios({
      method: "post",
      url: "./timer.php",
      data: {
        id: id,
        token: tokens,
        stud_id: studid,
        timer: times,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (view.message == "success") {
        } else {
        }
      })
      .catch((err) => {
        // toast.error("Error: Request Failed! Check your connection.." + err);
      });
  };

  const renderTime = ({ remainingTime }) => {

    localStorage.setItem("timer", remainingTime);

    if (remainingTime === 0) {
      SubmitQuiz();
      return <div className="timer fs-2 fw-bold">Too late..</div>;
    }
    return (
      <div className="timer text-center text-dark">
        <div className="text fs-4 text-dark  mb-2">Remaining</div>
        <div className="value display-3 text-dark fw-bold">
          {Math.round(remainingTime / 60)}
        </div>
        <div className="text fs-4 text-dark  mt-2">Minutes</div>
      </div>
    );
  };

  const renderQuizList = () => {
    if (quizlist == "") {
      return <></>;
    } else if (quizlist) {
      return quizlist.map((quizlistt, indexx) => {
        return (
          <>
            {quizlistt.ans && quizlistt.answer ? (
              <div className="col-md-1">
              <button
                type="button"
                class="btn btn-success mb-2"
                onClick={() => {
                  fetchQuiz(indexx + 1);
                  ResetForm();
                }}
              >
                {quizlistt.id}
              </button>
              </div>
            ) : (
              <div className="col-md-1">
              <button
                type="button"
                class="btn btn-outline-danger mb-2"
                onClick={() => {
                  fetchQuiz(indexx + 1);
                  ResetForm();
                }}
              >
                {quizlistt.id}
              </button>
              </div>
            )}
          </>
        );
      });
    } else {
      return <></>;
    }
  };

  if (!quiz.id) {
    new Audio("../assets/audio/done.mp3").play();
  }

  const [answer, setAnswer] = useState(null);

  //console.log(answer);

  const ResetForm = () => {
    document.getElementById("create-course-form").reset();
  };

  const Next_Func = (data) => {
    window.scrollTo(200, 600);
    ResetForm();
    if (answer) {
      axios({
        method: "post",
        url: "./quiz_check.php",
        data: {
          token: task.token,
          id: id,
          stud_id: studid,
          answer: answer,
          quesid: data - 1,
        },
        config: { headers: { "Content-Type": "multiport/form-data" } },
      })
        .then(function (response) {
          const view = response.data;
          if (response.data.message === "success") {
            fetchQuizList();
            //fetchData();
            fetchQuiz(data);
          } else {
            toast.error("Failed: Oops! Something went wrong. Try again!", {
              hideProgressBar: true,
              draggable: true,
              position: "top-center",
              icon: true,
              autoClose: 6000,
            });
          }
        })
        .catch((err) => {
          toast.error("Error: Request Failed! Check your connection..");
        });
    } else {
      fetchQuiz(data);
    }
  };

  const Prev_Func = (data) => {
    window.scrollTo(200, 600);
    fetchQuiz(data);
    ResetForm();
  };

  const SubmitQuiz = () => {
    axios({
      method: "post",
      url: "./score.php",
      data: {
        id: id,
        token: tokens,
        stud_id: studid,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (view.message === "success") {
          navigate("../score");
        } else {
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check your connection.." + err);
      });
  };

  if (task.statused === "Completed") {
    navigate("../score");
  } else {
    return (
      <>
        <nav class="navbar navbar-expand-xl navbar-default fixed-top shadow-none">
          <div class="container-fluid px-lg-4 px-0">
            <a class="navbar-brand fs-10 text-dark">St. Augustine</a>
          </div>
        </nav>

        <div class="pt-lg-6 pb-lg-12 pt-6 pb-12 bg-wgreen">
          <div class="container pt-8">
            <div class="row align-items-center">
              <div class="col-xl-9 col-lg-9 col-md-9 mb-5">
                <div>
                  <h1 class="text-dark display-5 fw-semi-bold">
                    Hello {fname_1},
                  </h1>
                </div>
              </div>

              <div class="col-xl-3 col-lg-3 col-md-3 px-11 px-lg-0 px-md-0">
                <CountdownCircleTimer
                  isPlaying
                  size={200}
                  duration={task.timed}
                  colors={["#00b894", "#fa983a", "#EA2027", "#A30000"]}
                  colorsTime={[2000, 1200, 0]}
                  onComplete={() => ({ shouldRepeat: true, delay: 1 })}
                >
                  {renderTime}
                </CountdownCircleTimer>
              </div>
            </div>
          </div>
        </div>

        <div class="pb-10">
          <div class="px-lg-3 px-2">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12 mt-n8 mb-4 mb-lg-0">
                <div class="card rounded-3">
                  <div class="card-header border-bottom-3 p-4">
                    {quiz.id ? (
                      <div className="fs-3 fw-bold text-dark">
                        {quiz.info_title}
                      </div>
                    ) : (
                      <div className="fs-3 fw-bold text-primary">
                        Hurray! You have reached the END.
                      </div>
                    )}
                  </div>

                  <div class="card-body px-lg-8">
                    {quiz.id ? (
                      <>
                        <div className="fs-4 text-dark mb-4">{quiz.info}</div>

                        {quiz.image ? 
                        <div className="row mb-4">
                          <div className="col-md-4">
                            {quiz.id >= 71 && quiz.id < 76 ? (
                              <div className="mb-5">
                                <img
                                  src={`https://learninghub.smartedung.com/sac/uploads/figure.png`}
                                  alt=""
                                  class="rounded img-4by3-xl"
                                  style={{ width: "100%" }}
                                />
                              </div>
                            ) : <></>}

                            {quiz.image ? (
                              <p className="mb-4 text-center fw-bold fs-4 text-primary">
                                Question has this image
                              </p>
                            ) : (
                              <></>
                            )}
                            <img
                              src={`https://learninghub.smartedung.com/sac/uploads/${quiz.image}`}
                              alt=""
                              class="rounded img-4by3-xl mb-5"
                              style={{ width: "100%" }}
                            />
                          </div>

                          <div className="col-md-8 mb-6">
                            <form id="create-course-form">
                              <div className="row pt-5">
                                <div class="col-lg-12 mb-4">
                                  <div className="text-dark fs-3">
                                   Question {quiz.id}. {quiz.question}?
                                  </div>
                                </div>

                                <div class="col-lg-12 mb-4">
                                  <div class="form-check form-check-custom form-check-lg ms-4">
                                    <input
                                      class="form-check-input"
                                      type="radio"
                                      value="A"
                                      id="flexCheckboxA"
                                      name="option"
                                      required
                                      onChange={(e) =>
                                        setAnswer(e.target.value)
                                      }
                                    />
                                    <label
                                      class="form-check-label text-dark fs-4"
                                      for="flexCheckboxA"
                                    >
                                      A. {quiz.ans1}
                                    </label>
                                  </div>
                                </div>

                                <div class="col-lg-12 mb-4">
                                  <div class="form-check form-check-custom form-check ms-4">
                                    <input
                                      class="form-check-input"
                                      type="radio"
                                      value="B"
                                      id="flexCheckboxB"
                                      name="option"
                                      required
                                      onChange={(e) =>
                                        setAnswer(e.target.value)
                                      }
                                    />
                                    <label
                                      class="form-check-label text-dark fs-4"
                                      for="flexCheckboxB"
                                    >
                                      B. {quiz.ans2}
                                    </label>
                                  </div>
                                </div>

                                <div class="col-lg-12 mb-4">
                                  <div class="form-check form-check-custom form-check ms-4">
                                    <input
                                      class="form-check-input"
                                      type="radio"
                                      value="C"
                                      id="flexCheckboxC"
                                      name="option"
                                      required
                                      onChange={(e) =>
                                        setAnswer(e.target.value)
                                      }
                                    />
                                    <label
                                      class="form-check-label text-dark fs-4"
                                      for="flexCheckboxC"
                                    >
                                      C. {quiz.ans3}
                                    </label>
                                  </div>
                                </div>

                                <div class="col-lg-12 mb-4">
                                  <div class="form-check form-check-custom form-check ms-4">
                                    <input
                                      class="form-check-input h-30px w-30px"
                                      type="radio"
                                      value="D"
                                      id="flexCheckboxD"
                                      name="option"
                                      required
                                      onChange={(e) =>
                                        setAnswer(e.target.value)
                                      }
                                    />
                                    <label
                                      class="form-check-label text-dark fs-4"
                                      for="flexCheckboxD"
                                    >
                                      D. {quiz.ans4}
                                    </label>
                                  </div>
                                </div>

                                <div className="col-6 pt-5">
                                  {quiz.id === 1 ? (
                                    <></>
                                  ) : (
                                    <button
                                      type="button"
                                      class="btn btn-dark text-left text-white px-5"
                                      onClick={() => {
                                        Prev_Func(quiz.id - 1);
                                      }}
                                    >
                                      <i class="bi bi-arrow-bar-left"></i> Prev
                                    </button>
                                  )}
                                </div>

                                <div className="col-6 align-right pt-5">
                                  <button
                                    type="button"
                                    class="btn btn-primary text-white px-5"
                                    onClick={() => {
                                      Next_Func(quiz.id + 1);
                                    }}
                                  >
                                    Next <i class="bi bi-arrow-bar-right"></i>
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        :
                        <div className="col-md-12 mb-6">
                            <form id="create-course-form">
                              <div className="row pt-5">
                                <div class="col-lg-12 mb-4">
                                  <div className="text-dark fs-3">
                                    Question {quiz.id}. {quiz.question.replace(/@/g, "√")}?
                                  </div>
                                </div>

                                <div class="col-lg-12 mb-4">
                                  <div class="form-check form-check-custom form-check-lg ms-4">
                                    <input
                                      class="form-check-input text-dark"
                                      type="radio"
                                      value="A"
                                      id="flexCheckboxA"
                                      name="option"
                                      required
                                      onChange={(e) =>
                                        setAnswer(e.target.value)
                                      }
                                    />
                                    <label
                                      class="form-check-label text-dark fs-4"
                                      for="flexCheckboxA"
                                    >
                                      A. {quiz.ans1}
                                    </label>
                                  </div>
                                </div>

                                <div class="col-lg-12 mb-4">
                                  <div class="form-check form-check-custom form-check ms-4">
                                    <input
                                      class="form-check-input text-dark"
                                      type="radio"
                                      value="B"
                                      id="flexCheckboxB"
                                      name="option"
                                      required
                                      onChange={(e) =>
                                        setAnswer(e.target.value)
                                      }
                                    />
                                    <label
                                      class="form-check-label text-dark fs-4"
                                      for="flexCheckboxB"
                                    >
                                      B. {quiz.ans2}
                                    </label>
                                  </div>
                                </div>

                                <div class="col-lg-12 mb-4">
                                  <div class="form-check form-check-custom form-check ms-4">
                                    <input
                                      class="form-check-input text-dark"
                                      type="radio"
                                      value="C"
                                      id="flexCheckboxC"
                                      name="option"
                                      required
                                      onChange={(e) =>
                                        setAnswer(e.target.value)
                                      }
                                    />
                                    <label
                                      class="form-check-label text-dark fs-4"
                                      for="flexCheckboxC"
                                    >
                                      C. {quiz.ans3}
                                    </label>
                                  </div>
                                </div>

                                <div class="col-lg-12 mb-4">
                                  <div class="form-check form-check-custom form-check ms-4">
                                    <input
                                      class="form-check-input text-dark"
                                      type="radio"
                                      value="D"
                                      id="flexCheckboxD"
                                      name="option"
                                      required
                                      onChange={(e) =>
                                        setAnswer(e.target.value)
                                      }
                                    />
                                    <label
                                      class="form-check-label text-dark fs-4"
                                      for="flexCheckboxD"
                                    >
                                      D. {quiz.ans4}
                                    </label>
                                  </div>
                                </div>

                                <div className="col-6 pt-5">
                                  {quiz.id === 1 ? (
                                    <></>
                                  ) : (
                                    <button
                                      type="button"
                                      class="btn btn-dark text-left text-white px-5"
                                      onClick={() => {
                                        Prev_Func(quiz.id - 1);
                                      }}
                                    >
                                      <i class="bi bi-arrow-bar-left"></i> Prev
                                    </button>
                                  )}
                                </div>

                                <div className="col-6 align-right pt-5">
                                  <button
                                    type="button"
                                    class="btn btn-primary text-white px-5"
                                    onClick={() => {
                                      Next_Func(quiz.id + 1);
                                    }}
                                  >
                                    Next <i class="bi bi-arrow-bar-right"></i>
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                          }
                      </>
                    ) : (
                      <div className="text-center mt-5">
                        {isMobile ? (
                          <img
                            src="../assets/images/background/award.gif"
                            alt=""
                            class="rounded img-4by3-xl mb-5"
                            style={{ width: "60%" }}
                          />
                        ) : (
                          <img
                            src="../assets/images/background/award.gif"
                            alt=""
                            class="rounded img-4by3-xl mb-5"
                            style={{ width: "34%" }}
                          />
                        )}

                        <div className="mb-6 fs-3">
                          Click on submit button below to get your score if you
                          are done.
                        </div>
                        <div className="mt-4">
                          <button
                            type="button"
                            class="btn btn-outline-primary mb-2 fs-3"
                            onClick={() => SubmitQuiz()}
                          >
                            <i class="bi bi-check2-circle"></i> Submit Exam Now
                          </button>
                        </div>
                      </div>
                    )}

                    <div className="pt-5 pb-5">
                      <div className="fs-5 fw-bold mb-3">
                        Note that the questions would appear RED when its not
                        yet answered and GREEN when it has been answered
                      </div>
                      <div className="row align-items-center">
                        {renderQuizList()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ToastContainer />
      </>
    );
  }
};

export default Exam;
