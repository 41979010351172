
import { createSlice } from '@reduxjs/toolkit';

const initialStateValue = {fullname: "", studid: "", email: "", phone: "", total: "", stud_class: ""};

export const userSlice = createSlice({
    name: 'user',
    initialState: {value: initialStateValue},
    reducers: {
        register: (state, action) =>{
            state.value = action.payload;
        },

        logout: (state) =>{
            state.value = initialStateValue
        },
    },
});

export const {register, logout} = userSlice.actions;

export default userSlice.reducer;
