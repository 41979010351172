import React, { useState, useEffect } from "react";

import { isMobile } from "react-device-detect";

import { useParams, useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";

import { register } from "../features/Users";

import { useSelector } from "react-redux";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import "simplebar/dist/simplebar.min.css";

import axios from "axios";

import Chart from "react-apexcharts";

const Score = () => {
  const { id } = useParams();

  const dispatch = useDispatch();

  const get_user_data = window.localStorage.getItem("student_data");

  useEffect(() => {
    //window.scrollTo(50, 50);
    dispatch(register(JSON.parse(get_user_data)));
  }, []);

  const getusers = useSelector((state) => state.user.value);

  const fullname = getusers.fullname;

  const studid = getusers.studid;

  const classid = getusers.stud_class;

  let fname_1;

  if (fullname) {
    fname_1 = fullname.split(" ")[1];
  } else {
    fname_1 = "";
  }

  window.history.pushState(null, "", window.location.href);
  window.onpopstate = function () {
    window.history.pushState(null, "", window.location.href);
  };

  const [task, setTask] = useState([]);

  useEffect(() => {
    fetchData();
    new Audio("../assets/audio/completed.mp3").play();
  }, [id, classid, studid]);

  const fetchData = () => {
    axios({
      method: "post",
      url: "./",
      data: {
        id: id,
        classd: classid,
        studid: studid,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (view.message === "failed") {
          //toast.error("Error: Request Failed! Check your connection..");
        } else {
          setTask(view);
          //window.scrollTo(0, document.body.scrollHeight);
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check your connection..");
      });
  };

  const config_ = {
    series: [task.total],
    options: {
      plotOptions: {
        radialBar: {
          startAngle: -250,
          endAngle: 110,
          background: "#000",
          hollow: {
            margin: 0,
            size: "80%",
            background: "transparent",
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: "front",
            dropShadow: {
              enabled: false,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24,
            },
          },
          track: {
            background: "#e55039",
            strokeWidth: "90%",
            margin: 0, // margin is in pixels
            dropShadow: {
              enabled: false,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35,
            },
          },

          tooltips: {
            enabled: true,
            intersect: true,
            mode: "nearest",
            bodySpacing: 5,
            yPadding: 10,
            xPadding: 10,
            caretPadding: 0,
            displayColors: true,
            cornerRadius: 4,
            footerSpacing: 0,
            titleSpacing: 0,
          },

          dataLabels: {
            showOn: "always",
            name: {
              offsetY: -20,
              show: false,
              color: "transparent",
              fontSize: "8px",
            },
            value: {
              formatter: function (val) {
                return val + "%";
              },
              color: "#000",
              fontSize: "30px",
              show: true,
            },
          },
        },
      },
      theme: {
        palette: "palette5",
      },
      stroke: {
        lineCap: "round",
      },
      labels: ["Total Score Mark"],
    },
  };

  return (
    <>
      <nav class="navbar navbar-expand-xl navbar-default fixed-top shadow-none">
        <div class="container-fluid px-lg-4 px-0">
          <a class="navbar-brand fs-10 text-dark">St. Augustine</a>
        </div>
      </nav>

      <div class="pt-lg-6 pb-lg-12 pt-6 pb-12 bg-gradient-mix-shade">
        <div class="container pt-8">
          <div class="row align-items-center">
            <div class="col-xl-12 col-lg-12 col-md-12 mb-5">
              <div>
                <h1 class="text-white display-5 fw-semi-bold pt-10">
                  Hurray! {fname_1} you have completed your test.
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pb-10">
        <div class="container">
          <div class="row">
            <div class="col-lg-8 col-md-12 col-12 mt-n8 mb-4 mb-lg-0">
              <div class="card rounded-3">
                <div class="card-header border-bottom-3 p-4">
                  <div className="fs-3 fw-bold text-dark">
                    Performance Statistics
                  </div>
                </div>

                <div class="card-body row">
                  <div className="col-md-4">
                    <Chart
                      options={config_.options}
                      series={config_.series}
                      type="radialBar"
                      height={250}
                    />
                  </div>

                  <div className="col-md-8">
                    <div className="mb-4 fs-3 fw-bold text-dark">Gradings</div>

                    <div className="fs-3 mb-4">
                      <i class="bi bi-dot text-sucess"></i>
                      {task.total}% Pass mark
                    </div>

                    <div className="fs-3 mb-8">
                      <i class="bi bi-dot text-danger"></i>
                      {100 - task.total}% Fail mark
                    </div>

                    {task.total >= 40 ? (
                      <div className="mb-4 fs-10 fw-bold text-success">
                        Congratulations you have been admitted!
                      </div>
                    ) : (
                      <div className="mb-4 fs-10 fw-bold text-danger">
                        Oops! Sorry you have been denied admission.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-12 mt-lg-n22">
              <div class="card mb-3 mb-6">
                <div class="px-1 pt-6">
                  {task.total <= 89 ? (
                    <div
                      class="d-flex justify-content-center position-relative rounded py-15 border-white border rounded-3 bg-cover"
                      style={{
                        backgroundImage:
                          "url(../assets/images/background/award_.gif)",
                      }}
                    ></div>
                  ) : task.total >= 90 ? (
                    <div
                      class="d-flex justify-content-center position-relative rounded py-20 border-white border rounded-3 bg-cover"
                      style={{
                        backgroundImage:
                          "url(../assets/images/background/award_1.gif)",
                      }}
                    ></div>
                  ) : (
                    <></>
                  )}
                </div>

                <div class="card-body">
                  {task.total <= 89 ? (
                    <div className="display-3 text-center text-dark fw-bold">
                      Master
                    </div>
                  ) : task.total >= 90 ? (
                    <div className="display-3 text-center text-dark fw-bold">
                      Veteran
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default Score;
